import React from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    div,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { NavLink, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import cookie from "react-cookies";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateScore, populateStore, requestFailedModal } from '../../actions';
import ApiClient from "../../api/ApiClient";
import { MdModeEdit } from "react-icons/md";
import DuoRoundedIcon from '@material-ui/icons/DuoRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import coins from '../../resources/coins.png';
import VisiblePointsTotal from '../gamification/VisiblePointsTotal';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

// const options = [
//     'Explore', 'Record', 'Verify', 'Vocabulary'
// ];

// const options = [
// 'Record'
// ];

class TopNavContainer extends React.Component {

    constructor(props) {
        super(props);
        this.apiClient = new ApiClient();
        this.toggle = this.toggle.bind(this);
        this.dropDownMenu = React.createRef();
        this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
        this.state = {
            isOpen: false,
            isDropdownOpen: false,
            defaultProfileImg: 'profile.svg',
            signedInGroupId: "",
            activeLink: false
        };
        this.FB = window.FB;
        this.style = {
            light: "ffffff"
        };

        this.personURL = this.props.profileImg;
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    textDecoration = {
        textDecoration: "underline"
    }

    componentDidMount() {

        // Set active state of dropdown
        if (window.location.pathname.includes("verify") || window.location.pathname.includes("record") || window.location.pathname.includes("explore")) {
            this.setState({ activeLink: true })
        }

        this.apiClient.getJSON(this, "/getUser/byId")
            .then((res) => {
                if (res && res.status) {
                    if (!res.isLoggedOut) {
                        this.setState({ user: res.data });
                    }
                }
                else if (res && res.httpServerError) {
                    // throw "Something Went Wrong"
                }
            });

    }
    componentWillReceiveProps(props) {
        if (!this.state.user) {

            this.apiClient.getJSON(this, "/getUser/byId")
                .then((res) => {
                    if (res && res.status) {
                        if (!res.isLoggedOut) {
                            this.setState({ user: res.data });
                        }
                    }
                    else if (res && res.httpServerError) {
                        // throw "Something Went Wrong"
                    }
                });
        }
    }

    logout = async () => {
        localStorage.removeItem("jwtToken");
        window.location.href = process.env.PUBLIC_URL + "/tpLogin";
    };

    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    toggleUserDropdown2 = (state) => {
        if (state) {
            this.setState(prevState => ({
                isDropdownOpen: state
            }));
        }
        else {
            setTimeout(() => {
                this.setState(prevState => ({
                    isDropdownOpen: state
                }));
            }, 100);
        }

    }

    toggleUserDropdown() {
        this.setState(prevState => ({
            isDropdownOpen: !prevState.isDropdownOpen
        }));
    }
    responseFacebook = (response) => {

    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    getApplicationNode = () => {
        return document.getElementById('root');
    };

    handleClick = (url, name, description) => {
        this.FB.ui(
            {
                method: 'feed',
                name: name,
                link: url,
                picture: '',
                caption: 'ASL',
                description: description,
                message: ""
            });
    }


    navigateTo(url) {
        if (url !== "/verify" && url !== "/record" && url !== "/explore" && url !== "/vocab") {
            this.setState({ activeLink: false })
        }
        this.props.history.push(url)
    }

    onDropdownSelect = (selection) => {
        let targetUrl = ""
        switch (selection.label) {
            case 'Explore':
                targetUrl = "/explore";
                break;
            case 'Record':
                targetUrl = "/record";
                break;
            case "Verify":
                targetUrl = "/verify";
                break;
            case 'Vocabulary':
                targetUrl = "/vocab";
                break;
            default:
                break;
        }
        this.setState({ activeLink: true });
        this.navigateTo(targetUrl);
    }

    render() {
        this.links = [
            {
                text: "Explore",
                url: "/explore"
            },
            // {
            //     text: "ASL Wiki",
            //     url: "/wiki"
            // },
            {
                text: "About",
                url: "/info"
            },
            {
                text: <AccountBoxRoundedIcon alt="User options" />,
                url: '/profile',
                className: 'userinfo',
                user: true
            }
        ];


        const defaultOption = "Dictionary"

        //const loginClass = this.props.disableLogin ? "nav-anchorLink disabled" : "nav-anchorLink";
        const navLinks = this.links.map((item, index) => {
            if (!item.user) {
                return (
                    <div key={index}>
                        <NavLink to={item.url} exact={item.exact} className='nav-link'
                            activeclassname="nav-link-active" onClick={() => this.navigateTo(item.url)} aria-current="page">
                            {item.text}
                        </NavLink>
                    </div>
                )
            } else if (item.user) {
                if (this.state.user) {
                    return (<div className="nav-account" onClick={() => this.toggleUserDropdown2(true)}>
                        <div className="profile-clicker">
                            <Dropdown toggle={this.toggleUserDropdown} ref={this.dropDownMenu} style={{ background: "white !important", color: "red !important" }} className="nav-item" key={index} isOpen={this.state.isDropdownOpen}  >
                                <DropdownToggle className="icon">
                                    <AccountCircleRoundedIcon style={{ color: "#7A22D1" }} />
                                </DropdownToggle>
                                <DropdownMenu className="profile-image" id="userProfileMenu">
                                    <DropdownItem className="nav-anchorLink" onClick={() => {
                                        this.toggleUserDropdown2(false);
                                    }} tag={Link} to="/userProfile" aria-label="MyProfilelink">
                                        My Profile
                                    </DropdownItem>

                                    <DropdownItem className="nav-anchorLink" onClick={() => this.logout()} aria-label="Signoutlink">
                                        Sign out
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <div className="nav-item nav-name ">
                                <span >{this.state.user && this.state.user.userName}</span>
                            </div>
                        </div>
                        <div className="nav-item score-item">
                            <span className="points-nav">
                                {/* <svg width="22" height="24" className="trophy" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.459473 8.03802C0.459473 11.772 3.44111 14.8153 7.14774 14.9296C8.10906 17.4196 10.2844 19.3176 12.9437 19.8736V22.3532C11.2867 22.8429 9.93337 24.0585 9.26205 25.6305H19.8653C19.1939 24.0582 17.841 22.8425 16.1836 22.3532V19.8736C18.8429 19.3172 21.0183 17.4193 21.9796 14.9296C25.6862 14.8153 28.6678 11.772 28.6678 8.03802V2.93607H22.5166V0.600098H6.61141V2.93607H0.459473V8.03802ZM25.6675 5.93737V8.03802C25.6675 9.93267 24.3096 11.5143 22.5166 11.8653V5.93737H25.6675ZM3.46044 5.93737H6.61141V11.8653C4.81809 11.5143 3.46044 9.93267 3.46044 8.03802V5.93737Z" fill="#FAB500" />
                                    <path d="M22.4036 27.3875H6.72412V30.6004H22.4036V27.3875Z" fill="#FAB500" />
                                </svg> */}
                                <span className="money-small">
                                    {/* <VisiblePointsTotal /> XP */}
                                </span>
                            </span>
                        </div>
                    </div>)
                } else {
                    return (
                        <div key={index + 0.1} className="login-btn">
                            <div onClick={() => this.props.history.push("/tpLogin")} aria-label="Login/Signup Link">
                                Sign in
                            </div>
                        </div>
                    )
                }
            }
        })
        return (
            <React.Fragment>
                <div className="nav-logo" onClick={() => this.navigateTo("/")}>
                    <svg width="142" height="26" viewBox="0 0 142 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 25.25C18.6274 25.25 24 19.8774 24 13.25C24 6.62258 18.6274 1.25 12 1.25C5.37258 1.25 0 6.62258 0 13.25V25.25H12Z" fill="#127BC2" />
                        <g clip-path="url(#clip0)">
                            <path d="M13.0775 9.84961H6.65505C6.07119 9.84961 5.59961 10.3212 5.59961 10.905V16.4293C5.59961 17.0131 6.07119 17.4847 6.65505 17.4847H13.0775C13.6614 17.4847 14.1329 17.0131 14.1329 16.4293V10.905C14.1329 10.3212 13.6614 9.84961 13.0775 9.84961Z" fill="white" />
                            <path d="M18.22 11.0402C18.0628 10.9279 17.8831 10.9055 17.7035 10.9504L14.8066 11.9609V15.7336L17.7259 16.7216C17.7933 16.7441 17.8607 16.7441 17.928 16.7441C18.0403 16.7441 18.1077 16.6992 18.22 16.6318C18.3772 16.5195 18.3996 16.3399 18.3996 16.1378V11.5343C18.3996 11.3322 18.3547 11.1525 18.22 11.0402Z" fill="white" />
                        </g>
                        <path d="M46.916 20.25H43.4785L42.4824 17.1348H37.502L36.5156 20.25H33.0977L38.1953 6.24609H41.9355L46.916 20.25ZM41.7598 14.7129L40.2559 10.0059C40.1452 9.6543 40.0671 9.23438 40.0215 8.74609H39.9434C39.9108 9.15625 39.8294 9.56315 39.6992 9.9668L38.1758 14.7129H41.7598Z" fill="#333333" />
                        <path d="M48.3195 19.7129V16.5879C48.8859 17.0632 49.5012 17.4212 50.1652 17.6621C50.8293 17.8965 51.4999 18.0137 52.177 18.0137C52.5741 18.0137 52.9191 17.9779 53.2121 17.9062C53.5116 17.8346 53.759 17.737 53.9543 17.6133C54.1561 17.4831 54.3059 17.3333 54.4035 17.1641C54.5012 16.9883 54.55 16.7995 54.55 16.5977C54.55 16.3242 54.4719 16.0801 54.3156 15.8652C54.1594 15.6504 53.9445 15.4518 53.6711 15.2695C53.4042 15.0872 53.0852 14.9115 52.7141 14.7422C52.343 14.5729 51.9426 14.4004 51.5129 14.2246C50.4191 13.7689 49.6021 13.2122 49.0617 12.5547C48.5279 11.8971 48.2609 11.1029 48.2609 10.1719C48.2609 9.44271 48.4074 8.81771 48.7004 8.29688C48.9934 7.76953 49.3905 7.33659 49.8918 6.99805C50.3996 6.65951 50.9855 6.41211 51.6496 6.25586C52.3137 6.0931 53.0168 6.01172 53.759 6.01172C54.4881 6.01172 55.1327 6.05729 55.6926 6.14844C56.259 6.23307 56.7798 6.36654 57.2551 6.54883V9.46875C57.0207 9.30599 56.7635 9.16276 56.4836 9.03906C56.2102 8.91536 55.927 8.81445 55.634 8.73633C55.341 8.65169 55.048 8.58984 54.7551 8.55078C54.4686 8.51172 54.1952 8.49219 53.9348 8.49219C53.5767 8.49219 53.2512 8.52799 52.9582 8.59961C52.6652 8.66471 52.4178 8.75911 52.216 8.88281C52.0142 9.00651 51.8579 9.15625 51.7473 9.33203C51.6366 9.5013 51.5812 9.69336 51.5812 9.9082C51.5812 10.1426 51.6431 10.3542 51.7668 10.543C51.8905 10.7253 52.0663 10.901 52.2941 11.0703C52.522 11.2331 52.7987 11.3958 53.1242 11.5586C53.4497 11.7148 53.8176 11.8776 54.2277 12.0469C54.7876 12.2812 55.2889 12.5319 55.7316 12.7988C56.1809 13.0592 56.565 13.3555 56.884 13.6875C57.203 14.0195 57.4471 14.4004 57.6164 14.8301C57.7857 15.2533 57.8703 15.748 57.8703 16.3145C57.8703 17.0957 57.7206 17.7533 57.4211 18.2871C57.1281 18.8145 56.7277 19.2441 56.2199 19.5762C55.7121 19.9017 55.1197 20.1361 54.4426 20.2793C53.772 20.4225 53.0624 20.4941 52.3137 20.4941C51.5454 20.4941 50.813 20.429 50.1164 20.2988C49.4263 20.1686 48.8273 19.9733 48.3195 19.7129Z" fill="#333333" />
                        <path d="M68.8148 20.25H60.475V6.24609H63.6293V17.6914H68.8148V20.25Z" fill="#333333" />
                        <path d="M86.8758 19.752C85.8536 20.2467 84.519 20.4941 82.8719 20.4941C80.7234 20.4941 79.034 19.8626 77.8035 18.5996C76.573 17.3366 75.9578 15.6536 75.9578 13.5508C75.9578 11.3112 76.6479 9.49479 78.0281 8.10156C79.4148 6.70833 81.2117 6.01172 83.4188 6.01172C84.7859 6.01172 85.9383 6.18424 86.8758 6.5293V9.56641C85.9383 9.00651 84.8706 8.72656 83.6727 8.72656C82.3576 8.72656 81.2964 9.13997 80.4891 9.9668C79.6818 10.7936 79.2781 11.9134 79.2781 13.3262C79.2781 14.6803 79.659 15.7611 80.4207 16.5684C81.1824 17.3691 82.2078 17.7695 83.4969 17.7695C84.7273 17.7695 85.8536 17.4701 86.8758 16.8711V19.752Z" fill="#333333" />
                        <path d="M90.877 8.66797C90.3561 8.66797 89.9297 8.51497 89.5977 8.20898C89.2656 7.89648 89.0996 7.51562 89.0996 7.06641C89.0996 6.60417 89.2656 6.22656 89.5977 5.93359C89.9297 5.64062 90.3561 5.49414 90.877 5.49414C91.4043 5.49414 91.8307 5.64062 92.1562 5.93359C92.4883 6.22656 92.6543 6.60417 92.6543 7.06641C92.6543 7.53516 92.4883 7.91927 92.1562 8.21875C91.8307 8.51823 91.4043 8.66797 90.877 8.66797ZM92.4004 20.25H89.3145V10.25H92.4004V20.25Z" fill="#333333" />
                        <path d="M101.343 20.1328C100.887 20.3737 100.2 20.4941 99.2824 20.4941C97.1079 20.4941 96.0207 19.3646 96.0207 17.1055V12.5254H94.3996V10.25H96.0207V8.0918L99.0969 7.21289V10.25H101.343V12.5254H99.0969V16.5684C99.0969 17.61 99.5103 18.1309 100.337 18.1309C100.663 18.1309 100.998 18.0365 101.343 17.8477V20.1328Z" fill="#333333" />
                        <path d="M104.954 8.66797C104.433 8.66797 104.006 8.51497 103.674 8.20898C103.342 7.89648 103.176 7.51562 103.176 7.06641C103.176 6.60417 103.342 6.22656 103.674 5.93359C104.006 5.64062 104.433 5.49414 104.954 5.49414C105.481 5.49414 105.907 5.64062 106.233 5.93359C106.565 6.22656 106.731 6.60417 106.731 7.06641C106.731 7.53516 106.565 7.91927 106.233 8.21875C105.907 8.51823 105.481 8.66797 104.954 8.66797ZM106.477 20.25H103.391V10.25H106.477V20.25Z" fill="#333333" />
                        <path d="M117.295 20.25H108.32V18.9707L113.339 12.5254H108.818V10.25H117.255V11.7734L112.5 17.9746H117.295V20.25Z" fill="#333333" />
                        <path d="M128.249 16.1289H121.725C121.83 17.5807 122.744 18.3066 124.47 18.3066C125.57 18.3066 126.537 18.0462 127.37 17.5254V19.752C126.445 20.2467 125.244 20.4941 123.766 20.4941C122.152 20.4941 120.899 20.0482 120.007 19.1562C119.115 18.2578 118.669 17.0078 118.669 15.4062C118.669 13.7461 119.151 12.431 120.114 11.4609C121.078 10.4909 122.262 10.0059 123.669 10.0059C125.127 10.0059 126.253 10.4388 127.048 11.3047C127.848 12.1706 128.249 13.3457 128.249 14.8301V16.1289ZM125.387 14.2344C125.387 12.8021 124.808 12.0859 123.649 12.0859C123.154 12.0859 122.725 12.291 122.36 12.7012C122.002 13.1113 121.784 13.6224 121.706 14.2344H125.387Z" fill="#333333" />
                        <path d="M140.043 20.25H136.967V14.6934C136.967 13.1439 136.413 12.3691 135.307 12.3691C134.773 12.3691 134.333 12.5742 133.988 12.9844C133.643 13.3945 133.471 13.9154 133.471 14.5469V20.25H130.385V10.25H133.471V11.832H133.51C134.245 10.6146 135.316 10.0059 136.723 10.0059C138.936 10.0059 140.043 11.3796 140.043 14.127V20.25Z" fill="#333333" />
                        <defs>
                            <clipPath id="clip0">
                                <rect width="12.8" height="12.8" fill="white" transform="translate(5.59961 6.84961)" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                {/* <div className="nav-dropdown-cont" style={{ "borderBottom": this.state.activeLink ? "2px solid #127BC2" : "none" }}>
                    <ReactDropdown options={options} onChange={this.onDropdownSelect} value={defaultOption}
                        placeholder="Select an option" />
                </div> */}
                <div className="nav-links">
                    {navLinks.slice(0, navLinks.length - 1)}
                </div>
                {navLinks[navLinks.length - 1]}
                {/* <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" style={{top:"30%"}} aria-hidden="false">
                    <Modal.Header closeButton>
                    <Modal.Title>Delete Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete your account? All of your videos and other data will be deleted, and will not be recoverable. Click yes to continue.</Modal.Body>
                    <Modal.Footer>
                    <button className="btn btn-primary mt-3" onClick={this.deleteUserAccount} aria-label="Click button to continue deleting the user account">Yes</button>
                    <button className="btn btn-secondary mt-3" onClick={this.handleClose} aria-label="Click button to Cancel user account deletion">No</button>
                    </Modal.Footer>
                </Modal> */}
            </React.Fragment>
        );
    }
}

TopNavContainer.propTypes = {
    video: PropTypes.object
}

const mapDispatchToProps = (dispatch) => ({
    updateScore,
    populateStore,
    requestFailedModal,
    dispatch
});

const TopNav = withRouter(connect(
    
    mapDispatchToProps
)(TopNavContainer));

export default TopNav;
